// SearchBar.js
import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import searchicon from '../images/search-pic.png';
import search1 from '../images/search-1.svg';
import { useUser } from '../context/UserContext';
import '../searchstyles.css';

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
    const { user, isLoggedIn } = useUser();

  const handleSearch = async () => {
  try {
    const response = await axios.get(`https://app.stocksleuth.xyz:5000/call?url=https://query1.finance.yahoo.com/v1/finance/search?q=${query}`);
    const quotes = response.data.quotes;

    // Convert query to lowercase for case-insensitive comparison
    const lowercaseQuery = query.toLowerCase();

    // Filter out results with exchanges other than NYSE and NASDAQ
    const filteredResults = quotes.filter((result) => result.exchange === 'NYQ' || result.exchange === 'NMS');
    const specificResults = quotes.filter(
      (result) =>
        (result.exchange === 'NYQ' && result.symbol.toLowerCase() === lowercaseQuery) ||
        (result.exchange === 'NMS' && result.symbol.toLowerCase() === lowercaseQuery)
    );

    setSearchResults(filteredResults);

    // If there is only one result, navigate directly to the stock page
    if (filteredResults.length === 1 || specificResults.length === 1) {
      navigate(`/stock/${filteredResults[0].symbol || specificResults[0].symbol}`);
    } else if (filteredResults.length > 1) {
      // If there are multiple results, navigate to the search results page
      navigate(`/search/${query}`);
    }
  } catch (error) {
    console.error('Error fetching search results', error);
  }
};
	const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  return (
    <div>
      {isLoggedIn ? (

		<div>
		{/*<input
		
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
		onKeyPress={handleKeyPress}
        placeholder="Search"
		style={{boxShadow: "0px 10px 60px #e1ecf880",outline:"none",borderColor: '#808EF6',paddingRight: "20px", background: '#FFFFFF', borderRadius: 12, border: "0px", width: '216px', height: '38px', textAlign: 'right',color: '#808EF6', fontSize: 31, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word', position: "fixed", top: "40px", right: "95px" }}
		placeholderStyle={{ color: '#808EF6' }}
	  />
	  <img onClick={handleSearch} src={searchicon} alt="Search" style={{width: "40px",
			height: "40px",
			position: "fixed",
			top: "27px",
			right: "348px",}}/>
			</div>
		) : (
		<div>*/}
		
		
		<div className="bar" style={{position: 'fixed', top: '40px', right: '95px'}}>
		<div className="search" >
		<div className="overlap-group" >
		<input placeholderStyle={{ color: '#808EF6' }} className="div" placeholder="Search" type="text" value={query}
        onChange={(e) => setQuery(e.target.value)}
		onKeyPress={handleKeyPress}/>
		<img className="img" alt="Search" src={search1} onClick={handleSearch}/>
		</div>
		</div>
		</div>
	  
	  
	  
      </div>
    ):<div></div>}
    </div>
  );
};

export default SearchBar;
