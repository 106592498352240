import TickerForm from '../TickerForm';
import React, { useState, useEffect } from 'react';
//
const Charts = () => {
  const [stockData, setStockData] = useState(null);
  const handleFetchData = (data) => {
    setStockData(data);
	};	

  return (
    <div>
      <h1>Portfolio Tracker</h1>
      <TickerForm onFetchData={handleFetchData} />

      {stockData && (
        <div>
          {/* Display stock information using stockData */}
        </div>
      )}
    </div>
  );
};
export default Charts;