import logo from './logo.svg';
import AuthComponent from './AuthComponent';
import React, { useState, useEffect, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  navigate,
  useLocation,
  Navigate,
  useNavigate,
  NavLink
} from "react-router-dom";
import './App.css';
import TickerForm from './TickerForm';
import Swiping from './pages/swiping';
import SignUpPage from './pages/signuppage';
import SignInPage from './pages/signinpage';
import PortfolioDisplayPage from './pages/PortfolioDisplayPage';
import OrderHistory from './pages/orderhistory.js';
import StockPurchase from './pages/stockpurchase';
import StockPage from './pages/StockPage';
import SearchBar from './pages/SearchBar';
import SignOut from './components/SignOut';
import { useUser} from './context/UserContext';
import UserContext from './context/UserContext';
import Charts from './pages/charts.js';
import Dashboard from './pages/dashboard.js';
import Scanner from './pages/scanner.js';
import SearchResults from './pages/SearchResults.js';
import AccountPage from './pages/accountpage.js';
import Battleship from './pages/battleship.js';
import Test from './pages/test.js';
import cookies from 'js-cookie';
import axios from 'axios';
import minilogo from './images/mini-logo.png';
import acct from './images/account-pic.png';
import lout from './images/logout-pic.png';
import beta from './images/beta-img.png';
import soon from './images/soon-img.png';
import controlPanelImage from './images/control-panel.png';
import chevronRight2Image from './images/chevron-right-2.svg';
import chevronRight2ImageW from './images/chevron-right-2white.svg';
import investmentPortfolioImage from './images/investment-portfolio.png';
import newsImage from './images/news.png';
import calendarImage from './images/calendar.png';
import messageQuestion1Image from './images/message-question-1.svg';
import ellipse8Image from './images/ellipse-8.svg';
import chevronDown2Image from './images/chevron-down-2.svg';
import funnel from './images/funnel.png';
import kopiaStockSleuthImage from './images/Kopia_STOCK_SLEUTH-removebg-preview-1.png';
import './newstyles.css';

import controlPanelImageW from './images/Control-PanelW.png';
import investmentPortfolioImageW from './images/investment-portfolioW.png';
import newsImageW from './images/NewsW.png';
import calendarImageW from './images/CalendarW.png';
import chevronDownW from './images/chevron-downW.png';
import funnelG from './images/funnelG.png';



function App() {
  const { setUser } = useContext(UserContext);
  const { user, isLoggedIn } = useUser();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('dashboard');
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState('');
	const isPortfolio = location.pathname === '/Portfolio' || location.pathname === '/OrderHistory' || location.pathname === '/BuyStocks';
	const UserLog =  location.pathname === '/SignIn' || location.pathname === '/SignUp';
  const handleLogoClick = () => {
    // Navigate to the "/dashboard" route
    navigate('/');
  };
  useEffect(() => {
    const checkAuthentication = async () => {
      const authToken = cookies.get('authToken');
      if (authToken) {
		try {
			  const response = await axios.post('https://app.stocksleuth.xyz:5000/auth/validate-token', { token: authToken });
			  const user = response.data.user;

			  // Fetch the profile image separately
			  const profileImageResponse = await axios.get('https://app.stocksleuth.xyz:5000/auth/getpfp', {
				params: {
				  username: user.username,
				},
			  });

			  // Add the profile_image field to the user object
			  const updatedUser = {
				...user,
				profile_image: profileImageResponse.data.profile_image,
			  };

			  console.log(updatedUser);
			  setUser(updatedUser);
			} catch (error) {
          console.error('Token validation failed', error);
          // If token validation fails, you might want to remove the invalid token from cookies
          cookies.remove('authToken', { path: '/' });
        }
      }
    };

    checkAuthentication();
  }, [setUser]);
  
  

  useEffect(() => {
    // Update the current location when the route changes
    setCurrentLocation(location.pathname);
  }, [location.pathname]);
  const { logout } = useContext(UserContext);
  const handleSignOut = async () => {
    try {
      // Make an API request to the logout endpoint in your Node.js backend running locally
      await axios.post('https://app.stocksleuth.xyz:5000/auth/logout'); //await axios.post('http://localhost:5000/auth/logout');
      logout();
      // Once logged out, set the user in the context to null
      setUser(null);
	  logout();
	  navigate('/SignIn');
    } catch (error) {
      console.error('Sign out failed', error);
    }
  };
  const goToAccount = () => {
    // Navigate to the /account route when the image or text is clicked
    navigate('/account');
  };
  
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  
  
  return (
	
    <div className="App">
<div className="whole" style={{height: '100vh'}}>
		
	  {isLoggedIn ? (
	  <div>
		  
		<div className="box">
      <div className="side-menu">
	  <div className="sidebar-content">
        <div className="overlap">
		
		<div className="container">
			
          <NavLink to="/dashboard" className="dashboard sidebar-item" activeClassName="active-link">
		  {currentLocation === '/dashboard' ? (<>
				<div className="overlap-group"></div>
				<div style={{top:"-38px", position: 'relative'}}>
				<div className="text-wrapper"style={{ color: '#FFFFFF' }}>Dashboard</div>
				<img className="img" alt="Control panel" src={controlPanelImageW} /></div></>
			  ) : (<>
				<div className="white-overlap-group"></div>
				<div style={{top:"-38px", position: 'relative'}}>
				<div className="text-wrapper">Dashboard</div>
				<img className="img" alt="Control panel" src={controlPanelImage} /></div></>
			  )}
			
          </NavLink>
		  
          <NavLink to="/portfolio" className={`sidebar-item portfolio ${isDropdownOpen ? 'open' : ''}`}>
			  {currentLocation === '/portfolio' ? (<>
				<div className="overlap-group"></div>
				<div style={{top:"-38px", position: 'relative'}}>
			<div className="portfolio-header" >
				<div style={{ textDecoration: 'none', color: '#FDFBFF' }}><div style={{ color: '#FFFFFF' }} className="text-wrapper">Portfolio</div></div>
				
				<div style={{ textDecoration: 'none', color: '#FDFBFF' }}><img className="img" alt="Investment portfolio" src={investmentPortfolioImageW} /></div>
			</div>
            </div>
			  </>) : (<>
				<div className="white-overlap-group"></div>
				<div style={{top:"-38px", position: 'relative'}}>
			<div className="portfolio-header" >
				<div style={{ textDecoration: 'none', color: '#FDFBFF' }}><div className="text-wrapper">Portfolio</div></div>
				
				<div style={{ textDecoration: 'none', color: '#FDFBFF' }}><img className="img" alt="Investment portfolio" src={investmentPortfolioImage} /></div>
			</div>
            </div>
			  </>)}
			
          </NavLink>
		  
          <NavLink to="/scanner" className="scanner sidebar-item" activeClassName="active-link">
		  {currentLocation === '/scanner' ? (
				<div className="overlap-group">
				<div className="div">Scanner</div>
				
				<img className="funnel" alt="Funnel" src={funnel} />
				</div>
			  ) : (
				<div className="white-overlap-group">
				<div className="div" style={{ color: '#9197B3' }}>Scanner</div>
				
				<img className="funnel" alt="Funnel" src={funnelG} />
				</div>
			  )}
            
			
          </NavLink>
		  
          <NavLink to="/news" className="news active sidebar-item" activeClassName="active-link">
		  {currentLocation === '/news' ? (<>
				<div className="overlap-group"></div>
				<div style={{top:"-38px", position: 'relative'}}>
            <div className="text-wrapper-2" style={{ color: '#FFFFFF' }}>News</div>
            
            <img className="img" alt="News" src={newsImageW} />
			</div>
			  </>) : (<>
				<div className="white-overlap-group"></div>
				<div style={{top:"-38px", position: 'relative'}}>
            <div className="text-wrapper-2" >News</div>
            
            <img className="img" alt="News" src={newsImage} />
			</div>
			  </>)}
			
          </NavLink>
		  
          <NavLink to="/earnings-calendar" className="earnings-calendar sidebar-item" activeClassName="active-link">
		  {currentLocation === '/earnings-calendar' ? (<>
				<div className="overlap-group"></div>
				<div style={{top:"-38px", position: 'relative'}}>
				<div className="text-wrapper-2" style={{ color: '#FFFFFF' }}>Earnings Calendar</div>
				
				<img className="img" alt="Calendar" src={calendarImageW} /></div>
			  </>) : (<>
				<div className="white-overlap-group"></div>
				<div style={{top:"-38px", position: 'relative'}}>
				<div className="text-wrapper-2">Earnings Calendar</div>
				
				<img className="img" alt="Calendar" src={calendarImage} /></div>
			  </>)}
			
          </NavLink>
		  
          <NavLink to="/help" className="help sidebar-item" activeClassName="active-link ">
		  {currentLocation === '/help' ? (
				<div className="white-overlap-group"></div>
			  ) : (
				<div className="white-overlap-group"></div>
			  )}
			<div style={{top:"-38px", position: 'relative'}}>
            <img style={{width: "30px", height: '30px'}}className="message-question" alt="Message question" src={messageQuestion1Image} />
            <div className="text-wrapper-3">Help</div>
            </div>
          </NavLink>
		  
		  </div>
          <NavLink to="/account" className="account" activeClassName="active-link">
            <div className="overlap-2">
              <div className="text-wrapper-4">{user.username}</div>
              <div className="text-wrapper-5">Your Account</div>
            </div>
			
			<div
          style={{
            width: '42px', // Adjust the width and height as needed
            height: '42px',
            borderRadius: '50%', // This makes the container circular
            overflow: 'hidden', // This ensures the image stays within the circular container
          }}
        >
          <img
            src={`data:image/jpeg;base64,${user.profile_image}`}
            alt="pfp"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }} // This makes the image cover the circular container
          />
        </div>
			
			
            
            <div onClick={handleSignOut} style={{ textAlign: 'right', color: '#C9C9C9', fontSize: 30, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word', position: "absolute", bottom: "17px", left: "200px" }}>Log out</div>
          </NavLink>
		  
          <img className="chevron-down" alt="Chevron down" src={chevronDown2Image} />
          <div className="overlap-4">
            <div className="logo">
              <div className="overlap-5">
                <div className="text-wrapper-7">StockSleuth</div>
                <img className="kopia-STOCK-SLEUTH" alt="Kopia STOCK SLEUTH" src={kopiaStockSleuthImage} />
              </div>
            </div>
            <div className="text-wrapper-8">v.01</div>
          </div>
        </div>
		</div>
      </div>
    </div>
	</div>
	  ) : (
        <div>
		
        </div>
      )}
	  
	  
	  
		{UserLog && !isLoggedIn ||  location.pathname === '/' && !isLoggedIn? (
		<div className="content" style={{
			height: "100vh",
			alignItems: "center",
			justifyContent: "center",
			textAlign: "center",
			left: "0px",
			top: '0px',
			width:'100%',
			position: 'fixed'
		}}>
		<Routes>
		<Route path="/SignIn" element={<SignInPage />} />
		<Route path="/SignUp" element={<SignUpPage />} />
		<Route path="/" element={<Dashboard />} />
		</Routes>
		</div>):(
		<div className="content" style={{
			height: "100vh",
			alignItems: "center",
			justifyContent: "center",
			textAlign: "center",
			left: "306px",
			top: '0px',
			width:'100%',
			position: 'fixed'
		}}>
	  
      <Routes>
		<Route path="/SignIn" element={<SignInPage />} />
		<Route path="/SignUp" element={<SignUpPage />} />
		<Route path="/charts" element={<Charts />} />
		<Route path="/BuyStocks" element={<StockPurchase />} />
		<Route path="/Portfolio" element={<PortfolioDisplayPage />} />
		<Route path="/OrderHistory" element={<OrderHistory />} />
		<Route path="/Account" element={<AccountPage />} />
		<Route path="/stock/:ticker" element={<StockPage />} />
		<Route path="/" element={<Dashboard />} />
		<Route path="/dashboard" element={<Dashboard />} />
		<Route path="/scanner" element={<Scanner />} />
		<Route path="/Statki" element={<Battleship />} />
		<Route path="/search/:query" element={<SearchResults />} />
		<Route path="/test" element={<Test />} />
		<Route path="/swipe" element={<Swiping />} />
      </Routes>
	  <SearchBar />
	  {/*<h1>Account system</h1>
      {isLoggedIn ? (
        <div>
          <p>Welcome, <b>{user.user.username}</b>!</p>
          <SignOut />
        </div>
      ) : (
        <div>
          <SignUp />
          <SignIn />
        </div>
      )}*/}
		</div>)}
	  
	  </div>
	</div>
	
	
  );
}

export default App;





