import React, { useState, useEffect } from 'react';
import controlPanelImage from '../images/control-panel.png';
import chevronRight2Image from '../images/chevron-right-2.svg';
import chevronRight2ImageW from '../images/chevron-right-2white.svg';
import investmentPortfolioImage from '../images/investment-portfolio.png';
import newsImage from '../images/news.png';
import calendarImage from '../images/calendar.png';
import messageQuestion1Image from '../images/message-question-1.svg';
import ellipse8Image from '../images/ellipse-8.svg';
import chevronDown2Image from '../images/chevron-down-2.svg';
import funnel from '../images/funnel.png';
import kopiaStockSleuthImage from '../images/Kopia_STOCK_SLEUTH-removebg-preview-1.png';
import '../newstyles.css';

const Test = () => {
	
  return (
    <div className="box">
      <div className="side-menu">
        <div className="overlap">
          <div className="dashboard">
            <div className="text-wrapper">Dashboard</div>
            <img className="img" alt="Control panel" src={controlPanelImage} />
          </div>
          <div className="portfolio">
            <div className="text-wrapper">Portfolio</div>
            <img className="chevron-right" alt="Chevron right" src={chevronRight2Image} />
            <img className="img" alt="Investment portfolio" src={investmentPortfolioImage} />
          </div>
          <div className="scanner">
            <div className="overlap-group">
              <div className="div">Scanner</div>
              <img className="chevron-right-2" alt="Chevron right" src={chevronRight2ImageW} />
              <img className="funnel" alt="Funnel" src={funnel} />
            </div>
          </div>
          <div className="news">
            <div className="text-wrapper-2">News</div>
            <img className="chevron-right-3" alt="Chevron right" src={chevronRight2Image} />
            <img className="img" alt="News" src={newsImage} />
          </div>
          <div className="earnings-calendar">
            <div className="text-wrapper-2">Earnings Calendar</div>
            <img className="chevron-right-3" alt="Chevron right" src={chevronRight2Image} />
            <img className="img" alt="Calendar" src={calendarImage} />
          </div>
          <div className="help">
            <img className="message-question" alt="Message question" src={messageQuestion1Image} />
            <div className="text-wrapper-3">Help</div>
            <img className="chevron-right-4" alt="Chevron right" src={chevronRight2Image} />
          </div>
          <div className="account">
            <div className="overlap-2">
              <div className="text-wrapper-4">Stan</div>
              <div className="text-wrapper-5">Your Account</div>
            </div>
            <img className="ellipse" alt="Ellipse" src={ellipse8Image} />
          </div>
          <img className="chevron-down" alt="Chevron down" src={chevronDown2Image} />
          
          <div className="overlap-4">
            <div className="logo">
              <div className="overlap-5">
                <div className="text-wrapper-7">StockSleuth</div>
                <img className="kopia-STOCK-SLEUTH" alt="Kopia STOCK SLEUTH" src={kopiaStockSleuthImage} />
              </div>
            </div>
            <div className="text-wrapper-8">v.01</div>
          </div>
        </div>
      </div>
	  
	  
	  
	  
	  
	  
	  
    </div>
  );
};
export default Test;