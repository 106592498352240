// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UserProvider } from './context/UserContext';
import reportWebVitals from './reportWebVitals';
import cookies from 'js-cookie';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  navigate,
  useLocation
} from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
		
			<Router>
				<GoogleOAuthProvider clientId="856737466092-7skej4mad2kbv2e7c0kh9l582bc528pe.apps.googleusercontent.com">
				<App />
				</GoogleOAuthProvider>
			</Router>
		
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
