import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useUser } from '../context/UserContext';
import SignInPage from '../pages/signinpage';
import search1 from '../images/search-1.svg';
import { useTable, useSortBy, usePagination } from 'react-table';
import '../searchstyles.css';

const Scanner = () => {
  const { isLoggedIn } = useUser();
  const [marketOpen, SetMarketOpen] = useState(false);
  const [sortState, setSortState] = useState([{ id: 'symbol', desc: false }]);
  const [stockData, setStockData] = useState([]);
   const [BackupStockData, setBackupStockData] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);



	const filterData = () => {
  // Create a copy of BackupStockData
  const filteredData = [...BackupStockData];

  // Filter the copied array
  return filteredData.filter((item) => item.price >= minPrice && item.price <= maxPrice);
};
  
  
  
  useEffect(() => {
    // Call filterData function and update state with filtered data
    // You might want to replace setStockData with another state variable
    // if you want to preserve the original data
	//clearfilter();
	//console.log(BackupStockData);
	setStockData(BackupStockData);
    setStockData(filterData());
  }, [minPrice, maxPrice]);
  
  const clearfilter = () => {
	 //console.log(BackupStockData);
    setStockData(BackupStockData);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://app.stocksleuth.xyz:5000/auth/getstocktable');
        const responseData = await response.json();
        setStockData(responseData);
		setBackupStockData(responseData);
		const initialMinPrice = Math.min(...responseData.map(item => item.price));
		  const initialMaxPrice = Math.max(...responseData.map(item => item.price));
		  setMinPrice(initialMinPrice);
		  setMaxPrice(initialMaxPrice);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

   const columns = useMemo(
    () => [
      {
        Header: 'Company Name',
        accessor: 'security_name',
		style: { width: '70%', border: '5px solid red' },
		enableResizing: false, //disable resizing for just this column
		size: 200,
		className: 'company-name-column',
		maxSize: '50%',
      },
      {
        Header: 'Ticker',
        accessor: 'symbol',
		style: { width: '15%' },
		className: 'ticker-column',
		Cell: ({ row }) => (
        <td style={{ whiteSpace: 'nowrap', maxHeight: '20px', overflow: 'hidden', textOverflow: 'ellipsis', border: "0px" }}>
          <a href={`/stock/${row.original.symbol}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            {row.original.symbol}
          </a>
        </td>
      )
      },
      {
        Header: 'Price',
        accessor: 'price',
		style: { width: '15%' },
		className: 'price-column',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    previousPage,
	gotoPage,
    nextPage,
    canPreviousPage,
	filter,
    canNextPage,
    rows,
	size,
  } = useTable(
    {
      columns,
      data: stockData,
      initialState: { pageIndex: 0, pageSize: 8 },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
  const isMarketOpen = () => {
    const now = new Date();
    const nyTimeZone = 'America/New_York';
    //console.log('Current Time:', now.toLocaleString());
    //console.log('Time Zone:', nyTimeZone);

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: nyTimeZone,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    });

    const nyTime = formatter.format(now);
	//console.log('Current Time:', nyTime);
    const [hours, minutes] = nyTime.split(':').map(Number);
	const newMin = minutes + hours*60;
	//console.log('h:', hours);
    if( newMin > 570 && newMin < 960)
		return true;
	else
		return false;
  };

  console.log('Is Market Open:', isMarketOpen());
  SetMarketOpen(isMarketOpen());
}, []);

  const startRange = pageIndex * pageSize + 1;
  const endRange = Math.min((pageIndex + 1) * pageSize, rows.length);
	
	
   return (
    <div style={{ width: '100%', height: '100vh' }}>
      {isLoggedIn ? (
        <div>
          <div className="bigbox" style={{ top: 117 }}>
            <div className="overlap-9" style={{ top: 0 }}>
              <div style={{ height: '100%' }}>
                <h3 style={{ margin: 0, paddingLeft: 38, paddingTop: 30, textAlign: 'left' }}>Stocks</h3>
				{marketOpen ? (<><h5 style={{margin:0,paddingLeft: 38, paddingTop: 7, textAlign: 'left', color: '#16C098'}}>Market Open</h5></>) : (<><h5 style={{color: '#fcc84e', margin:0,paddingLeft: 38, paddingTop: 7, textAlign: 'left'}}>Market Closed</h5></>)}
                <div className="Otherbar" style={{ position: 'absolute', top: '44px', right: '214px' }}>
                  <div className="search">
                    <div className="overlap-group">
                      <input placeholderTextColor={'red'} className="div" placeholder="Search" type="text" />
                      <img className="img" alt="Search" src={search1} />
                    </div>
                  </div>
                </div>
                <div className="sortby" style={{ position: 'absolute', top: '44px', right: '44px' }} onClick={() => setFilterVisible(!filterVisible)}>
					<div className="search">
						<div className="overlap-group">
							<p style={{ margin: 0, color: '#b5b7c0', left: '15px' }} className="dropdown">
							Filter
							</p>
						</div>
					</div>
				</div> 
				{filterVisible ? (
					<>
						<div className="box">
							<div className="rectangle">
								<div className="text-wrapper7">Price</div>
								<div style={{position: 'absolute', top: 34, left: 8}}>
									<div style={{left: 0, top: 4, position: 'absolute', color: '#7E7E7E', fontSize: 12, wordWrap: 'break-word'}}>From</div>
									<div style={{left: 90, top: 4, position: 'absolute', color: '#7E7E7E', fontSize: 12, wordWrap: 'break-word'}}>To</div>
									<input
              type="text"
              className="inputField"
              style={{ color: '#7E7E7E', border: 0, padding: 0, width: 51, height: 27, left: 35, top: 0, position: 'absolute', background: 'white', borderRadius: 9, border: '1px #7E7E7E solid' }}
              value={minPrice}
              onChange={(e) => setMinPrice(parseFloat(e.target.value) || 0)}
            />
            <input
              type="text"
              className="inputField"
              style={{ color: '#7E7E7E', border: 0, padding: 0, width: 51, height: 27, left: 109, top: 0, position: 'absolute', background: 'white', borderRadius: 9, border: '1px #7E7E7E solid' }}
              value={maxPrice}
              onChange={(e) => setMaxPrice(parseFloat(e.target.value) || 0)}
            />
								</div>
							</div>
						</div>
					</>
				) : (
					<>
					
					</>
				)
				}

                <table {...getTableProps()} style={{ maxWidth: '96%', width: '96%', left: '2%', right: '2%', position: 'absolute', top: 97, height: '76.48%' }}>
  <thead style={{ height: '11.1%', width: '100%' }}>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()} style={{ height: '11.1%', color: '#B5B7C0'}} >
        {headerGroup.headers.map((column, columnIndex) => (
          <th
            {...column.getHeaderProps(column.getSortByToggleProps())}
            style={{ width: columnIndex === 0 ? '60%' : '20%' }}
          >
            {column.render('Header')}
            <span>
              {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
            </span>
          </th>
        ))}
      </tr>
    ))}
  </thead>
  <tbody {...getTableBodyProps()} style={{ height: '100%' }}>
    {page.map((row) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()} style={{ height: '11.1%', width: '100%' }}>
          {row.cells.map((cell, columnIndex) => (
            <td
              {...cell.getCellProps()}
              style={{
                maxHeight: '20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: columnIndex === 0 ? '60%' : '20%',
              }}
            >
              {cell.render('Cell')}
            </td>
          ))}
        </tr>
      );
    })}
  </tbody>
</table>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', bottom: '41px', position: 'absolute', left: '38px'}}>
				<span style={{color: '#b5b7c0'}}>
                    Showing {startRange} to {endRange} of {rows.length} entries
                  </span></div>
				  
				  
				  
				{pageIndex == 0 ? (
					<div className="pageSelector">
						
						
						<div className="frame" onClick={() => previousPage()} disabled={!canPreviousPage}>
							<div className="text-wrapper">
								&lt;
							</div>
						</div>
						
						
						
						<div className="frame" onClick={() => gotoPage(pageIndex)} style={{borderColor:'#5932ea', backgroundColor:'#5932ea', color: '#ffffff'}}>
							<div className="text-wrapper" style={{color: '#ffffff'}}>{pageIndex + 1}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex + 1)}>
							<div className="text-wrapper">{pageIndex + 2}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex + 2)}>
							<div className="text-wrapper">{pageIndex + 3}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex + 3)}>
							<div className="text-wrapper">{pageIndex + 4}</div>
						</div>
						<span style={{marginLeft: '12px'}}>...</span>
						<div className="frame" onClick={() => gotoPage(Math.ceil(stockData.length / pageSize) -1)}>
							<div className="text-wrapper">{Math.ceil(stockData.length / pageSize)}</div>
						</div>
						
						
						
						<div className="frame" onClick={() => nextPage()} disabled={!canNextPage}>
							<div className="text-wrapper">
								&gt;
							</div>
						</div>
						
					</div>
				  ) : pageIndex == 1 ? (
					<div className="pageSelector">
						
						
						<div className="frame" onClick={() => previousPage()} disabled={!canPreviousPage}>
							<div className="text-wrapper">
								&lt;
							</div>
						</div>
						
						
						
						<div className="frame" onClick={() => gotoPage(pageIndex-1)} >
							<div className="text-wrapper" >{pageIndex }</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex + 1)} style={{borderColor:'#5932ea', backgroundColor:'#5932ea', color: '#ffffff'}}>
							<div className="text-wrapper" style={{color: '#ffffff'}}>{pageIndex+1}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex + 3)}>
							<div className="text-wrapper">{pageIndex + 2}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex + 2)}>
							<div className="text-wrapper">{pageIndex + 3}</div>
						</div>
						<span style={{marginLeft: '12px'}}>...</span>
						<div className="frame" onClick={() => gotoPage(Math.ceil(stockData.length / pageSize) -1)}>
							<div className="text-wrapper">{Math.ceil(stockData.length / pageSize)}</div>
						</div>
						
						
						
						<div className="frame" onClick={() => nextPage()} disabled={!canNextPage}>
							<div className="text-wrapper">
								&gt;
							</div>
						</div>
						
					</div>
				  ) : pageIndex == Math.ceil(stockData.length / pageSize) -1 ? (
					<div className="pageSelector">
						
						
						<div className="frame" onClick={() => previousPage()} disabled={!canPreviousPage}>
							<div className="text-wrapper">
								&lt;
							</div>
						</div>
						
						
						
						<div className="frame" onClick={() => gotoPage(0)} >
							<div className="text-wrapper" >1</div>
						</div>
						<span style={{marginLeft: '12px'}}>...</span>
						<div className="frame" onClick={() => gotoPage(pageIndex-3)} >
							<div className="text-wrapper" >{pageIndex-2}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex -2)}>
							<div className="text-wrapper">{pageIndex-1}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex -1)}>
							<div className="text-wrapper">{pageIndex}</div>
						</div>
						
						<div style={{borderColor:'#5932ea', backgroundColor:'#5932ea', color: '#ffffff'}} className="frame" onClick={() => gotoPage(Math.ceil(stockData.length / pageSize) -1)}>
							<div className="text-wrapper" style={{color: '#ffffff'}}>{Math.ceil(stockData.length / pageSize)}</div>
						</div>
						
						
						
						<div className="frame" onClick={() => nextPage()} disabled={!canNextPage}>
							<div className="text-wrapper">
								&gt;
							</div>
						</div>
						
					</div>
				  ):pageIndex == Math.ceil(stockData.length / pageSize)-2 ? (
					<div className="pageSelector">
						
						
						<div className="frame" onClick={() => previousPage()} disabled={!canPreviousPage}>
							<div className="text-wrapper">
								&lt;
							</div>
						</div>
						
						
						
						<div className="frame" onClick={() => gotoPage(0)} >
							<div className="text-wrapper" >1</div>
						</div>
						<span style={{marginLeft: '12px'}}>...</span>
						<div className="frame" onClick={() => gotoPage(pageIndex -2)} >
							<div className="text-wrapper" >{pageIndex-1}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex -1)}>
							<div className="text-wrapper">{pageIndex}</div>
						</div>
						<div style={{borderColor:'#5932ea', backgroundColor:'#5932ea', color: '#ffffff'}} className="frame" onClick={() => gotoPage(pageIndex)}>
							<div className="text-wrapper" style={{color: '#ffffff'}}>{pageIndex+1}</div>
						</div>
						
						<div  className="frame" onClick={() => gotoPage(Math.ceil(stockData.length / pageSize) -1)}>
							<div className="text-wrapper" >{Math.ceil(stockData.length / pageSize)}</div>
						</div>
						
						
						
						<div className="frame" onClick={() => nextPage()} disabled={!canNextPage}>
							<div className="text-wrapper">
								&gt;
							</div>
						</div>
						
					</div>
				  ):(
					<div className="pageSelector">
						
						
						<div className="frame" onClick={() => previousPage()} disabled={!canPreviousPage}>
							<div className="text-wrapper">
								&lt;
							</div>
						</div>
						
						
						
						<div className="frame" onClick={() => gotoPage(0)} >
							<div className="text-wrapper" >1</div>
						</div>
						<span style={{marginLeft: '12px'}}>...</span>
						<div className="frame" onClick={() => gotoPage(pageIndex -1)} >
							<div className="text-wrapper" >{pageIndex}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex)} style={{borderColor:'#5932ea', backgroundColor:'#5932ea', color: '#ffffff'}}>
							<div className="text-wrapper" style={{color: '#ffffff'}}>{pageIndex+1}</div>
						</div>
						<div className="frame" onClick={() => gotoPage(pageIndex+1)}>
							<div className="text-wrapper">{pageIndex + 2}</div>
						</div>
						<span style={{marginLeft: '12px'}}>...</span>
						<div className="frame" onClick={() => gotoPage(Math.ceil(stockData.length / pageSize) -1)}>
							<div className="text-wrapper">{Math.ceil(stockData.length / pageSize)}</div>
						</div>
						
						
						
						<div className="frame" onClick={() => nextPage()} disabled={!canNextPage}>
							<div className="text-wrapper">
								&gt;
							</div>
						</div>
						
					</div>
				  )}
				
				
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: '100vw', height: '100vh', position: 'static', left: '0px' }}>
          <SignInPage />
        </div>
      )}
    </div>
  );
};
export default Scanner;
