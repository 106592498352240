import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../context/UserContext';
import { Pie } from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import StockPurchase from '../pages/stockpurchase.js';
import OrderHistory from '../pages/orderhistory.js';
import dayjs from 'dayjs';
import LineChart from '../images/lineChart.png';
import PieChart from '../images/pieChart.png';
import WhiteLine from '../images/GraphWhiteLine.png';
import WhitePie from '../images/WhitePie.png';
import Up from '../images/changeUp.png';
import Down from '../images/changeDown.png';
import Flat from '../images/noChange.png';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
ChartJS.register(ArcElement);
const PortfolioDisplayPage = () => {
  const [portfolio, setPortfolio] = useState({});
  const [stockData, setStockData] = useState({});
  const [stockData2, setStockData2] = useState({});
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalPurchaseValue, setTotalPurchaseValue] = useState(0);
  //const [totalProfit, setTotalProfit] = useState(0);
  const { user, isLoggedIn } = useUser();
  useEffect(() => {
  let sum = 0;
  Object.values(portfolio).forEach(purchases => {
    purchases.forEach(purchase => {
      sum += purchase.shares * purchase.purchasePrice;
    });
  });
  setTotalPurchaseValue(sum);
}, [portfolio]);
  const [lineChartData, setLineChartData] = useState({
  options: {
    xaxis: {
      categories: [],
	
    },
    yaxis: [
      {
        seriesName: 'Portfolio Value',
        title: {
          text: 'Portfolio Value',
        },
      },
    ],
    
  },
  series: [
    {
      name: 'Portfolio Value',
      data: [],
    },
  ],
});
  const [isLineChart, setIsLineChart] = useState(false);
  const [range, setRange] = useState('1y');
  const [interval, setInterval] = useState('1wk');
	
	
	
	
	
	
	const fetchHistoricalStockData = async (ticker) => {
    try {
      const proxyUrl = 'https://app.stocksleuth.xyz:5000/call?url=';
      const apiUrl = `https://query1.finance.yahoo.com/v8/finance/chart/${ticker}?metrics=regularMarketPrice&interval=${interval}&range=${range}`;
		const encodedApiUrl = encodeURIComponent(apiUrl);
      const response = await axios.get(proxyUrl + encodedApiUrl);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch historical data for ${ticker}`, error);
      return {};
    }
  };
	
	const handleRangeButtonClick = (selectedRange) => {
  let selectedInterval;

  switch (selectedRange) {
    case '1d':
      selectedInterval = '1m';
      break;
    case '5d':
      selectedInterval = '5m';
      break;
    case '1mo':
      selectedInterval = '1h';
      break;
    case '3mo':
    case '6mo':
      selectedInterval = '1d';
      break;
    case '1y':
      selectedInterval = '1d';
      break;
    case '2y':
    case '5y':
      selectedInterval = '1wk';
      break;
    case 'max':
      selectedInterval = '1mo';
      break;
    case 'ytd':
      selectedInterval = '1d';
      break;
    default:
      selectedInterval = '1d';
  }
	console.log('Timestamps:', selectedRange);
console.log('interval', selectedInterval);
  setRange(selectedRange);
  setInterval(selectedInterval);
  fetchDataAndUpdateChart();
  //fetchStockData();
};
	
	
	
	
	
	
	
	
	const fetchDataAndUpdateChart = async () => {
  try {
    // Fetch stock data for each stock in the portfolio
    const stockTickers = Object.keys(portfolio);
    const stockDataPromises = stockTickers.map((ticker) => fetchStockData(ticker));

    // Wait for all stock data requests to complete
    const stockDataResults = await Promise.all(stockDataPromises);

    // Combine stock data with portfolio data
    const combinedData = Object.fromEntries(stockTickers.map((ticker, index) => [ticker, stockDataResults[index]]));
    setStockData(combinedData);

    const calculatedTotalBalance = Object.entries(portfolio).reduce((total, [stock, purchases]) => {
      return total + purchases.reduce((subTotal, purchase) => {
        const currentPrice = stockData[stock]?.[0]?.currentPrice || 0;
        return subTotal + (currentPrice * purchase.shares);
      }, 0);
    }, 0);
    setTotalBalance(calculatedTotalBalance);

    // Fetch historical stock data for each symbol/ticker
    const stockDataPromises2 = stockTickers.map((ticker) => fetchHistoricalStockData(ticker));
    const stockDataResults2 = await Promise.all(stockDataPromises2);

    // Combine stock data with portfolio data
    const combinedData2 = Object.fromEntries(stockTickers.map((ticker, index) => [ticker, stockDataResults2[index]]));
    setStockData2(combinedData2);

    // Calculate line chart data
    const timestamps = stockDataResults2[0]?.chart.result[0]?.timestamp || [];
const lineChartDataValues = timestamps.map((timestamp, index) => {
  const totalValue = Object.entries(portfolio).reduce((total, [stock, purchases]) => {
    const currentPrice = combinedData2[stock]?.chart.result[0]?.indicators.quote[0]?.close || [];
    const shares = purchases.reduce((subTotal, purchase) => subTotal + purchase.shares, 0);
    const priceAtTimestamp = currentPrice[timestamps.indexOf(timestamp)] || null;

    // If the priceAtTimestamp is null, use the previous value
    const value = priceAtTimestamp !== null ? priceAtTimestamp : (index > 0 ? totalValue[index - 1] : null);

    return [...total, value * shares];
  }, []);
  return totalValue.reduce((a, b) => a + b, 0).toFixed(2);
});

    // Set line chart data
    setLineChartData({
      options: {
            stroke: {
              width: [2], // Adjust the width as needed
              colors: ['#808EF6'], // Specify your desired colors
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: false,
              marker: {
                show: false,
              },
              onDatasetHover: {
                highlightDataSeries: false,
              },
              followCursor: false,
              intersect: false,
              
            },
            chart: {
              type: 'line',
              
              toolbar: {
                autoSelected: 'none',
                show: false,
              },
            },
            states: {
              normal: {
                filter: {
                  type: 'none',
                  value: 0,
                },
              },
              hover: {
                filter: {
                  type: 'none',
                  value: 0,
                },
              },
            },
            grid: {
              position: 'back',
              borderColor: '#FDFBFF',
            },
            xaxis: {
              type: 'category',
              tickAmount: 5,
			  axisTicks: {
				  show: false,
				  
			  },
              categories: timestamps.map((timestamp) => new Date(timestamp * 1000).toLocaleDateString()),
			  labels: {
				  show: false,
                formatter: function (val) {
                  // Check if lineChartData and its options are available
                  if (lineChartData && lineChartData.options && lineChartData.options.chart) {
                    const range = lineChartData.options.chart.range;

                    // Customize date-time formats based on the selected range
                    if (range === '1d') {
                      // Format for 1 day
                      return dayjs(val).format('HH:mm');
                    } else if (['5d', '1mo', '3mo', '6mo'].includes(range)) {
                      // Format for 5 days, 1 month, 3 months, 6 months
                      return dayjs(val).format('DD HH');
                    } else if (['1y', '2y', '5y', 'ytd', 'max'].includes(range)) {
                      // Format for 1 year, 2 years, 5 years, year-to-date, max
                      return dayjs(val).format('MMM YYYY');
                    }
                  }

                  // Default format for other cases
                  return dayjs(val).format('MMM');
                },
				rotate: 0,
              },
              crosshairs: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
            },
			zoom: {
				enabled: false
			  },
            yaxis: {
              opposite: true,
			  labels: {
			  style: {
					  colors: [],
					  fontSize: '10px',
					  fontFamily: 'Lexend',
					  fontWeight: 400,
					  cssClass: 'apexcharts-yaxis-label',
			  },},
              //tickAmount: 6,
              /*labels: {
                formatter: function (val, index) {
                  return val !== null ? val.toFixed(2) : val;
                },
              },*/
              forceNiceScale: true,
              tooltip: {
                enabled: false,
              },
              crosshairs: {
                show: false,
              },
            },
            markers: {
              size: 0,
            },
          },
      series: [
        {
          name: 'Portfolio Value',
          data: lineChartDataValues,
        },
      ],
    });
  } catch (error) {
    console.error('Failed to fetch and update chart data', error);
  }
};
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
  useEffect(() => {
	
    const fetchData = async () => {
      try {
        if (isLoggedIn && user) {
          // Fetch portfolio data
          const portfolioResponse = await axios.get(`https://app.stocksleuth.xyz:5000/auth/portfolio?username=${user.username}`);
          const portfolioData = portfolioResponse.data.portfolio;
			
			
          // Process portfolio data to combine multiple purchases of the same stock
          const processedPortfolio = processPortfolioData(portfolioData);

          // Set the processed portfolio data
          setPortfolio(processedPortfolio || {});

          // Fetch stock data for each stock in the portfolio
          const stockTickers = Object.keys(processedPortfolio);
          const stockDataPromises = stockTickers.map((ticker) => fetchStockData(ticker));

          // Wait for all stock data requests to complete
          const stockDataResults = await Promise.all(stockDataPromises);

          // Combine stock data with portfolio data
          const combinedData = Object.fromEntries(stockTickers.map((ticker, index) => [ticker, stockDataResults[index]]));
          setStockData(combinedData);
		  
		  const calculatedTotalBalance = Object.entries(portfolio).reduce((total, [stock, purchases]) => {
            return total + purchases.reduce((subTotal, purchase) => {
              const currentPrice = stockData[stock]?.[0]?.currentPrice || 0;
              return subTotal + (currentPrice * purchase.shares);
            }, 0);
          }, 0);
          setTotalBalance(calculatedTotalBalance);

          // Fetch historical stock data for each symbol/ticker
          const stockDataPromises2 = stockTickers.map((ticker) => fetchHistoricalStockData(ticker));
          const stockDataResults2 = await Promise.all(stockDataPromises2);

          // Combine stock data with portfolio data
          const combinedData2 = Object.fromEntries(stockTickers.map((ticker, index) => [ticker, stockDataResults2[index]]));
          setStockData2(combinedData2);

          // Calculate line chart data
          const timestamps = stockDataResults2[0]?.chart.result[0]?.timestamp || [];
          const lineChartDataValues = timestamps.map((timestamp) => {
			//console.log(timestamp);
			console.log(portfolio);
            const totalValue = Object.entries(processedPortfolio).reduce((total, [stock, purchases]) => {
				
              const currentPrice = combinedData2[stock]?.chart.result[0]?.indicators.quote[0]?.close || [];
              const shares = purchases.reduce((subTotal, purchase) => subTotal + purchase.shares, 0);
              const priceAtTimestamp = currentPrice[timestamps.indexOf(timestamp)] || 0;
              return total + priceAtTimestamp * shares;
            }, 0);
            return totalValue.toFixed(2);
          }); 

// Set line chart data
setLineChartData({
  options: {
            stroke: {
              width: [2], // Adjust the width as needed
              colors: ['#808EF6'], // Specify your desired colors
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: false,
              marker: {
                show: false,
              },
              onDatasetHover: {
                highlightDataSeries: false,
              },
              followCursor: false,
              intersect: false,
              
            },
            chart: {
              type: 'line',
              
              toolbar: {
                autoSelected: 'none',
                show: false,
              },
            },
            states: {
              normal: {
                filter: {
                  type: 'none',
                  value: 0,
                },
              },
              hover: {
                filter: {
                  type: 'none',
                  value: 0,
                },
              },
            },
            grid: {
              position: 'back',
              borderColor: '#FDFBFF',
            },
			zoom: {
				enabled: false
			  },
            xaxis: {
              type: 'category',
              tickAmount: 4,
			  tickPlacement: 'between',
			  
			  axisTicks: {
				  show: false,
				  
			  },
              categories: timestamps.map((timestamp) => new Date(timestamp * 1000).toLocaleDateString()),
			  labels: {
				  hideOverlappingLabels: true,
				  show: false,
				  
                formatter: function (val) {
					
                  // Check if lineChartData and its options are available
                  if (lineChartData && lineChartData.options && lineChartData.options.chart) {
                    

                    // Customize date-time formats based on the selected range
                    if (range === '1d') {
                      // Format for 1 day
                      return dayjs(val).format('HH:mm');
                    } else if (['5d', '1mo', '3mo', '6mo'].includes(range)) {
                      // Format for 5 days, 1 month, 3 months, 6 months
                      return dayjs(val).format('DD.MM');
                    } else if (['1y', '2y', '5y', 'ytd', 'max'].includes(range)) {
                      // Format for 1 year, 2 years, 5 years, year-to-date, max
                      return dayjs(val).format('MMM YYYY');
                    }
                  }

                  // Default format for other cases
                  return dayjs(val).format('MMM');
                },
				rotate: 0,
              },
              crosshairs: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              opposite: true,
			  labels: {
			  style: {
					  colors: [],
					  fontSize: '10px',
					  fontFamily: 'Lexend',
					  fontWeight: 400,
					  cssClass: 'apexcharts-yaxis-label',
			  },},
              //tickAmount: 6,
              /*labels: {
                formatter: function (val, index) {
                  return val !== null ? val.toFixed(2) : val;
                },
              },*/
              forceNiceScale: true,
              tooltip: {
                enabled: false,
              },
              crosshairs: {
                show: false,
              },
            },
            markers: {
              size: 0,
            },
          },
  
  
  
  
  
  
  
  
  
  
  
  
  
  series: [
    {
      name: 'Portfolio Value',
      data: lineChartDataValues,
    },
  ],
});
			
			
			
			
			
			
			
			
			
        }
		
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();
  }, [user, isLoggedIn, range, interval]);
	
  const fetchStockData = async (ticker) => {
  try {
    const proxyUrl = 'https://app.stocksleuth.xyz:5000/call?url=';
    const apiUrl = `https://query1.finance.yahoo.com/v8/finance/chart/${ticker}?metrics=regularMarketPrice&interval=1d&range=1d`;
	const encodedApiUrl = encodeURIComponent(apiUrl);
    const response = await axios.get(proxyUrl + encodedApiUrl);
    const data = response.data.chart.result[0].indicators.quote[0];

    // Round the values to 2 decimal places
    const currentPrice = parseFloat(data.close.slice(-1)[0]).toFixed(2);
	
    // Return an array of stock data for each purchase
    return Array(data.open.length).fill().map(() => ({
      currentPrice,
    }));
  } catch (error) {
    console.error(`Failed to fetch data for ${ticker}`, error);
    return Array(1).fill({ currentPrice: 'N/A' });
  }
};

  const processPortfolioData = (portfolioData) => {
  const processedPortfolio = {};

  Object.keys(portfolioData).forEach((stock) => {
    // Combine multiple purchases for the same stock
    const combinedPurchases = combinePurchases(portfolioData[stock]);

    processedPortfolio[stock] = combinedPurchases;
  });

  return processedPortfolio;
};
useEffect(() => {
	const fetchSum = async () => {
    try {
      // ... (existing code)

      // Calculate total balance
      const calculatedTotalBalance = pieChartData.reduce((total, data) => total + data.totalHolding, 0);
      setTotalBalance(calculatedTotalBalance);

    } catch (error) {
      console.error('Failed to fetch total', error);
    }
  };

  fetchSum();
}, [user, isLoggedIn, Date.now()]);


const combinePurchases = (purchases) => {
  const combinedPurchases = purchases.reduce((result, purchase) => {
    const existingPurchase = result.find((p) => p.stock === purchase.stock);

    if (existingPurchase) {
      // Convert shares and purchase price to numbers before performing calculations
      existingPurchase.shares += Number(purchase.shares);
      existingPurchase.weightedPurchasePrice += Number(purchase.shares) * Number(purchase.purchasePrice);
    } else {
      // Convert shares and purchase price to numbers before adding to the result array
      result.push({
        stock: purchase.stock,
        shares: Number(purchase.shares),
        purchasePrice: Number(purchase.purchasePrice),
        weightedPurchasePrice: Number(purchase.shares) * Number(purchase.purchasePrice),
      });
    }

    return result;
  }, []);

  // Calculate the weighted average purchase price for each combined purchase
  combinedPurchases.forEach((purchase) => {
    purchase.purchasePrice = (purchase.weightedPurchasePrice / purchase.shares).toFixed(2);
    delete purchase.weightedPurchasePrice; // Remove the weightedPurchasePrice property
  });

  return combinedPurchases;
};
///
const generateRandomColors = (count) => {
    const baseHue = 270; // Purple hue
  const baseSaturation = 50; // Adjust as needed
  const baseLightness = 50; // Adjust as needed

  const colors = [];

  for (let i = 0; i < count; i++) {
    const hue = (baseHue + i * (360 / count)) % 360;
    const color = `hsla(${hue}, ${baseSaturation}%, ${baseLightness}%, 0.7)`;
    colors.push(color);
  }

  return colors;
  };
const pieChartData = Object.entries(portfolio)
  .map(([stock, purchases]) => {
    const totalHolding = purchases.reduce((total, purchase) => {
      const currentPrice = stockData[stock]?.[0]?.currentPrice || 0; // Assuming only one purchase for simplicity
      return total + (currentPrice * purchase.shares);
    }, 0);

    // Include stocks only if they have non-zero total holding
    if (totalHolding > 0) {
      return { stock, totalHolding };
    }
    return null;
  })
  .filter(Boolean);

  // Extract labels and data for the pie chart
  const pieChartLabels = pieChartData.map((data) => data.stock);
  const pieChartDataValues = pieChartData.map((data) => data.totalHolding);

  // Define colors for the chart
  const pieChartColors = generateRandomColors(pieChartData.length);

  // Configure the pie chart data
  const pieChartOptions = {
  
  plugins: {
    datalabels: {
		font: {
        family: 'Lexend', // Set the desired font family
        size: 12, // Set the desired font size // Set the desired font weight
      },
      color: (context) => {
        // Get the background color of the chart
        const backgroundColor = context.dataset.backgroundColor[context.dataIndex];

        // Determine a contrasting text color based on the background color
        const textColor = getContrastColor(backgroundColor);

        return textColor;
      },
      formatter: (value, context) => {
        const label = context.chart.data.labels[context.dataIndex];
        return label;
      },
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const dataIndex = tooltipItem.index;
        const label = data.labels[dataIndex];
        const value = data.datasets[0].data[dataIndex];
        return `${label}: $${value.toFixed(2)}`;
      },
    },
  },
};
function getContrastColor(backgroundColor) {
  // Calculate the relative luminance of the background color
  const luminance = 0.2126 * backgroundColor[0] + 0.7152 * backgroundColor[1] + 0.0722 * backgroundColor[2];

  // Use white text for dark backgrounds and black text for light backgrounds
  return luminance > 0.5 ? '#000' : '#fff';
}
  
const handleButtonP = () => {
    setIsLineChart(false);
    // Additional logic or actions you want to perform on button click
  };
const handleButtonL = () => {
    setIsLineChart(true);
    // Additional logic or actions you want to perform on button click
  };
  
  
  const totalProfit = Object.entries(portfolio).reduce((total, [stock, purchases]) => {
  return total + purchases.reduce((subTotal, purchase) => {
    const currentPrice = stockData[stock]?.[0]?.currentPrice || 0; // Current price of the stock
    const purchasePrice = purchase.purchasePrice; // Purchase price of the stock
    const shares = purchase.shares; // Number of shares

    // Calculate profit for this purchase only if shares are positive
    if (shares > 0) {
      const profit = (currentPrice - purchasePrice) * shares;
      return subTotal + profit;
    } else {
      return subTotal;
    }
  }, 0);
}, 0);
  
let profitColorClass = '';
if (totalProfit < 0) {
  profitColorClass = 'negative';
} else if (totalProfit > 0) {
  profitColorClass = 'positive';
} else {
  profitColorClass = 'neutral';
}
///
  return (
  <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
	
    {isLoggedIn ? (
      <div>
        {user && user.username ? (
          <div >
            
            {Object.keys(portfolio).length === 0 ? (
              <div>
                <p>Your portfolio is empty.</p>
                <Link to="/buystocks">
                  <button>New order</button>
                </Link>
              </div>
            ) : (<>
			
              <div style={{ display: 'flex' }}>
                

                
                
				<div>
					<div className="text-wrapper-39">Your Portfolio</div>
					<div className="holdings">
						<div style={{ marginTop: '30px', flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
						  <table style={{ width: "90%" }}>
							<thead>
							  <tr>
								<th>Stock</th>
								<th>Shares</th>
								<th>Purchase Price</th>
								<th>Current Price</th>
								<th>Price Change</th>
								<th>Total Holding</th>
								<th>Total Profit</th>
							  </tr>
							</thead>
							<tbody>
							  {Object.entries(portfolio).map(([stock, purchases]) => (
								purchases.map((purchase, index) => {
								  if (purchase.shares !== 0) {
									const currentPrice = stockData[stock]?.[index]?.currentPrice || 'N/A';
									const priceChange = currentPrice !== 'N/A' ? (currentPrice - purchase.purchasePrice).toFixed(2) : 'N/A';
									const totalProfit = currentPrice !== 'N/A' ? ((currentPrice - purchase.purchasePrice) * purchase.shares).toFixed(2) : 'N/A';
									const totalHolding = currentPrice !== 'N/A' ? (currentPrice * purchase.shares).toFixed(2) : 'N/A';

									return (
									  <tr key={`${stock}-${index}`}>
										<td><Link to={`/stock/${stock}`}>{stock}</Link></td>
										<td>{purchase.shares}</td>
										<td>{purchase.purchasePrice}</td>
										<td>{currentPrice}</td>
										<td>{priceChange}</td>
										<td>{totalHolding}</td>
										<td>{totalProfit}</td>
									  </tr>
									);
								  }
								  return null;
								})
							  ))}
							</tbody>
						  </table>
						</div>
					</div>
					
					
					<div className="piechart" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						{isLineChart ? (<>
						<button onClick={handleButtonP}  className = "chartsButton" style= {{position: 'absolute', padding: 0, margin: 0, right: 20, top: 20, width: 25, height: 25}}><img src={PieChart} style= {{width: '100%', height: '100%'}}/></button>
						<button onClick={handleButtonL} className = "chartsButtonActive" style= {{position: 'absolute', padding: 0, margin: 0, right: 47, top: 20, width: 25, height: 25}}><img src={WhiteLine}  style= {{width: '100%', height: '100%'}}/></button>
						</>) : (<>
						<button onClick={handleButtonP}  className = "chartsButtonActive" style= {{position: 'absolute', padding: 0, margin: 0, right: 20, top: 20, width: 25, height: 25}}><img src={WhitePie} style= {{width: '100%', height: '100%'}}/></button>
						<button onClick={handleButtonL} className = "chartsButton" style= {{position: 'absolute', padding: 0, margin: 0, right: 47, top: 20, width: 25, height: 25}}><img src={LineChart}  style= {{width: '100%', height: '100%'}}/></button>
						</>)}
						{ !isLineChart ? (<>
							<div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%', height: '80%'}}>
								<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
									<Pie data={{ labels: pieChartLabels, datasets: [{data: pieChartDataValues, backgroundColor: pieChartColors, },],}} options={pieChartOptions} plugins={[ChartDataLabels]}/>
								</div>
							</div>
							
						</>) : (<>
							<div style={{ flex: '1', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%', height: '80%' }}>
								<div style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
									<Chart
										options={lineChartData.options}
										series={lineChartData.series}
										type={'line'}
										height={'100%'}
									  width={'100%'}
									/>
								</div>
							</div>
							
							<div className="RangeSelector" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 30px' }}>
								<button className={`range-button ${range === '1d' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('1d')}>1d</button>
								<button className={`range-button ${range === '5d' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('5d')}>5d</button>
								<button className={`range-button ${range === '1mo' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('1mo')}>1mo</button>
								<button className={`range-button ${range === '3mo' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('3mo')}>3mo</button>
								<button className={`range-button ${range === '6mo' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('6mo')}>6mo</button>
								<button className={`range-button ${range === '1y' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('1y')}>1y</button>
								<button className={`range-button ${range === '2y' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('2y')}>2y</button>
								<button className={`range-button ${range === '5y' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('5y')}>5y</button>
								
								<button className={`range-button ${range === 'ytd' ? 'active' : ''}`} onClick={() => handleRangeButtonClick('ytd')}>ytd</button>
							</div>
						</>)}
					</div>
					
					
					
					
			  <div className="neworder"><StockPurchase/></div>
			  <div className="balance">${totalBalance.toFixed(2)} 
			  <span style={{display: 'flex'}} className={`profit ${profitColorClass}`}>{totalProfit < 0 ? <img src={Down} style={{width: 20, paddingRight: 5}} alt="Down" /> : totalProfit > 0 ? <img src={Up} style={{width: 20,paddingRight: 5}} alt="Up" /> : <img src={Flat} style={{width: 20, paddingRight: 5}} alt="Flat" />}${totalProfit.toFixed(2)} ({((totalProfit / totalPurchaseValue) * 100).toFixed(2)}%)  All time</span></div>
			  <div className="history" ><OrderHistory /></div>
					</div>
				</div>
            </>)}
          </div>
        ) : (
          <p>No username found. Please sign in.</p>
        )}
      </div>
    ) : (
      <div>
        <p>User not logged in</p>
      </div>
    )}
	
	
	
	
	
	
	{/*
  <div style={{width: "100%", height: "100vh",}}>
	
	{isLoggedIn ? (
	<div>
		<div className="text-wrapper-39">Your Potfolio</div>
				<div className="overview">
					
				</div>

				<div className="bigbox">
					<div className="overlap-9" style={{top:0}}>
						<div style = {{height: '100%'}}>
							<h3 style={{margin:0,paddingLeft: 38, paddingTop: 30, textAlign: 'left'}}>Stocks</h3>
						</div>
					</div>
				</div>

				
			</div>
				
	) : (<div style={{width:'100vw', height: '100vh', position: 'static', left: '0px'}}><SignInPage/></div>)}
    </div>
  
  
  */}
	
  </div>
  
);

};

export default PortfolioDisplayPage;