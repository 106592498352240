import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../context/UserContext';

const StockPurchase = () => {
  const { user, isLoggedIn } = useUser();
  const [ticker, setTicker] = useState('');
  const [shares, setShares] = useState('');
  const [purchasePrice, setPurchasePrice] = useState('');
  const [message, setMessage] = useState('');
  const [orderType, setOrderType] = useState('buy');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedTime, setSelectedTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
  

  const handleTickerChange = (newTicker) => {
    setTicker(newTicker.toUpperCase());
  };

  const fetchLatestPrice = async () => {
    if (ticker) {
      try {
        const proxyUrl = 'https://corsproxy.io/?';
        const apiUrl = `https://query1.finance.yahoo.com/v8/finance/chart/${ticker}?metrics=regularMarketPrice&interval=1d&range=1d`;

        const response = await axios.get(proxyUrl + apiUrl);
        const data = response.data.chart.result[0].indicators.quote[0];

        // Round the values to 2 decimal places
        const currentPrice = parseFloat(data.close.slice(-1)[0]).toFixed(2);

        setPurchasePrice(currentPrice);
      } catch (error) {
        console.error('Failed to fetch current market price', error);
      }
    }
  };

  const handlePurchase = async (orderType) => {
    let finalShares = orderType === 'sell' ? -shares : shares; // Add or subtract shares based on order type
    let finalPurchasePrice = purchasePrice;
    let finalDatetime = new Date().toISOString(); // Default to current date and time

    try {
        // Fetch current market price if purchase price is not provided
        if (!purchasePrice) {
            const proxyUrl = 'https://corsproxy.io/?';
            const apiUrl = `https://query1.finance.yahoo.com/v8/finance/chart/${ticker}?metrics=regularMarketPrice&interval=1d&range=1d`;

            const response = await axios.get(proxyUrl + apiUrl);
            const data = response.data.chart.result[0].indicators.quote[0];

            // Round the values to 2 decimal places
            const currentPrice = parseFloat(data.close.slice(-1)[0]).toFixed(2);

            finalPurchasePrice = currentPrice;
        }

        // Check if the date and time are provided, otherwise, use the current date and time
        if (selectedDate || selectedTime) {
            const date = selectedDate || new Date().toLocaleDateString();
            const time = selectedTime || new Date().toLocaleTimeString();
            finalDatetime = new Date(`${date} ${time}`).toISOString();
        }

        const data = {
            ticker,
            shares: finalShares,
            purchasePrice: finalPurchasePrice,
            username: user.username,
            datetime: finalDatetime,
        };

        // Make an API request to the purchase endpoint
        await axios.post('https://app.stocksleuth.xyz:5000/auth/purchase', data);
        window.location.reload();
        // Update state to display success message
        setMessage('Stock purchased successfully');
    } catch (error) {
        console.error('Stock purchase failed', error);

        // Update state to display error message
        setMessage('Stock purchase failed');
    }
};

const handlePurchaseWithOrderType = async (orderType) => {
    setOrderType(orderType);
    await handlePurchase(orderType);
};
  return (
    <div style={{ width: '100%', height: '100%'}}>
      {isLoggedIn ? (
        <div style={{width: '100%', height: '100%'}}>
			<h3 style={{margin: 0, position: 'absolute', top: 8, left: 28, fontSize: 24, fontWeight: 500}}>New Order</h3>
			<div style={{width: '100%',position: 'absolute', top: '-7%', bottom: 0}}>
				<p style={{fontSize: '14', fontWeight: 500, position: 'absolute', top: 64, marginLeft: 28, textAlign: 'left', marginTop: 0, marginBottom: 0}}>Ticker</p>
				<input className="tickerField" type="text" value={ticker} placeholder = "AAPL" onChange={(e) => handleTickerChange(e.target.value)} onBlur={fetchLatestPrice}/>
				<p style={{fontSize: '14', fontWeight: 500, position: 'absolute', top: 64, marginLeft: 'calc(11px + 32.84% + 32px)', textAlign: 'left', marginTop: 0, marginBottom: 0}}># of shares</p>
				<input placeholder="27" className="numField" type="number" value={shares} onChange={(e) => setShares(e.target.value)} />
				<p style={{fontSize: '14', fontWeight: 500, position: 'absolute', top: 'calc(85px + 13.2% + 8px)', marginLeft: 28, textAlign: 'left', marginTop: 0, marginBottom: 0}}>Price</p>
				<input placeholder="157.92" className="PriceField" type="number" value={purchasePrice} onChange={(e) => setPurchasePrice(e.target.value)} />
				<p style={{fontSize: '14', fontWeight: 500, position: 'absolute', top: 'calc(85px + 13.2% + 29px + 13.2% + 8px)', marginLeft: 28, textAlign: 'left', marginTop: 0, marginBottom: 0}}>Date</p>
				<input className="DateField" type="date" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
				<p style={{fontSize: '14', fontWeight: 500, position: 'absolute', top: 'calc(85px + 13.2% + 29px + 13.2% + 8px)', marginLeft: 'calc(11px + 62.58% + 31px)', textAlign: 'left', marginTop: 0, marginBottom: 0}}>Time</p>
				<input className="TimeField" type="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} />
				<button className="buy" onClick={() => handlePurchaseWithOrderType('buy')}>Buy</button>
				<button className="sell" onClick={() => handlePurchaseWithOrderType('sell')}>Sell</button>
			</div>
			
		  {/*<br />
          <label>
            Ticker:
            <input type="text" value={ticker} onChange={(e) => handleTickerChange(e.target.value)} onBlur={fetchLatestPrice}/>
          </label>
          <br />
          <label>
            Shares:
            <input type="number" value={shares} onChange={(e) => setShares(e.target.value)} />
          </label>
          <br />
          <label>
            Purchase Price:
            <input type="number" value={purchasePrice} onChange={(e) => setPurchasePrice(e.target.value)} />
			
          </label>
          <br />
          <label>
            Date:
            <input type="date" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
          </label>
          <br />
          <label>
            Time:
            <input type="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} />
          </label>
          <br />
		  <p style={{fontSize: 10}}>leave empty for the current price and date</p>
          <button onClick={handlePurchase}>Submit Order</button>
          <p>{message}</p>
		  
		  <p style={{ color: 'green', margin: '0px' }}>
            <input type="radio" value="buy" checked={orderType === 'buy'} onChange={(e) => setOrderType(e.target.value)} /> Buy
          </p>
          <p style={{ color: 'red', margin: '0px' }}>
            <input type="radio" value="sell" checked={orderType === 'sell'} onChange={(e) => setOrderType(e.target.value)} /> Sell
          </p>*/}
		  
		  
        </div>
      ) : (
        <div>
          <p>User not logged in</p>
        </div>
      )}
    </div>
  );
};

export default StockPurchase;