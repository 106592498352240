import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const SearchResults = () => {
  const { query } = useParams();
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get(`https://app.stocksleuth.xyz:5000/call?url=https://query1.finance.yahoo.com/v1/finance/search?q=${query}`);
        const quotes = response.data.quotes;

        // Convert query to lowercase for case-insensitive comparison
        const lowercaseQuery = query.toLowerCase();

        // Filter out results with exchanges other than NYSE and NASDAQ
        const filteredResults = quotes.filter((result) => result.exchange === 'NYQ' || result.exchange === 'NMS');

        setSearchResults(filteredResults);
      } catch (error) {
        console.error('Error fetching search results', error);
      }
    };

    fetchSearchResults();
  }, [query]);

  return (
    <div>
      <h2>Search Results for "{query}"</h2>
      <ul>
        {searchResults.map(result => (
          <li key={result.symbol}>
            {result.exchange === 'NYQ' || result.exchange === 'NMS' ? (
              <Link to={`/stock/${result.symbol}`}>{result.shortname}</Link>
            ) : (
              <span>{result.shortname} ({result.symbol}) - {result.exchDisp}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchResults;
