import TickerForm from '../TickerForm';
import React, { useState, useEffect, useContext } from 'react';
import { useUser } from '../context/UserContext';
import SignInPage from '../pages/signinpage';
import SignIn from '../components/SignIn';
import UserContext from '../context/UserContext';
//
const Dashboard = () => {
	const [stockData, setStockData] = useState(null);
	const { user, isLoggedIn } = useUser();
	const { setUser } = useContext(UserContext);
	const [marketOpen, SetMarketOpen] = useState(false);
	const handleFetchData = (data) => {
		setStockData(data);
	};	
	const [marketPrice, setMarketPrice] = useState(null);
  const [VIXmarketPrice, setVIXMarketPrice] = useState(null);
  const timestamp = new Date().getTime();
  


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://corsproxy.io/?https://query1.finance.yahoo.com/v8/finance/chart/%5EGSPC?metrics=high?&interval=1d&range=1mo&_=${timestamp}`);
        const data = await response.json();

        // Extract the regularMarketPrice from the API response
        const regularMarketPrice = data.chart.result[0]?.meta?.regularMarketPrice;

        // Round the regularMarketPrice to the tenth
        const roundedMarketPrice = regularMarketPrice ? parseFloat(regularMarketPrice).toFixed(1) : null;

        setMarketPrice(roundedMarketPrice);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchDataVIX = async () => {
      try {
        const response = await fetch(`https://corsproxy.io/?https://query1.finance.yahoo.com/v8/finance/chart/%5EVIX?metrics=high?&interval=1d&range=1mo&_=${timestamp}`);
        const data = await response.json();

        // Extract the regularMarketPrice from the API response
        const regularMarketPrice = data.chart.result[0]?.meta?.regularMarketPrice;

        // Round the regularMarketPrice to the tenth
        const roundedMarketPrice = regularMarketPrice ? parseFloat(regularMarketPrice).toFixed(2) : null;

        setVIXMarketPrice(roundedMarketPrice);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataVIX();
  }, []);
  
  
  
  const [monthlyChange, setMonthlyChange] = useState(null);
  const [VIXmonthlyChange, setVIXMonthlyChange] = useState(null);
  const [monthlyChangePos, setMonthlyChangePos] = useState(true);
  const [monthlyVIXChangePos, setMonthlyVIXChangePos] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://corsproxy.io/?https://query1.finance.yahoo.com/v8/finance/chart/%5EGSPC?metrics=high?&interval=1d&range=1mo'
        );
        const data = await response.json();

        const chartData = data.chart.result[0];
        const timestamps = chartData.timestamp;
        const closes = chartData.indicators.quote[0].open;

        let firstIndex = 0;
        while (firstIndex < closes.length && closes[firstIndex] === null) {
          firstIndex++;
        }

        let lastIndex = closes.length - 1;
        while (lastIndex >= 0 && closes[lastIndex] === null) {
          lastIndex--;
        }

        if (firstIndex < lastIndex) {
          const firstPrice = closes[firstIndex];
          const lastPrice = closes[lastIndex];
          const percentageChange = ((lastPrice - firstPrice) / firstPrice) * 100;
          setMonthlyChange(percentageChange.toFixed(1));
        } else {
          setMonthlyChange(null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setMonthlyChange(null);
      }
    };
	
	
	const fetchDataVIX = async () => {
      try {
        const response = await fetch(
          'https://corsproxy.io/?https://query1.finance.yahoo.com/v8/finance/chart/%5EVIX?metrics=high?&interval=1d&range=1mo'
        );
        const data = await response.json();

        const chartData = data.chart.result[0];
        const timestamps = chartData.timestamp;
        const closes = chartData.indicators.quote[0].open;

        let firstIndex = 0;
        while (firstIndex < closes.length && closes[firstIndex] === null) {
          firstIndex++;
        }

        let lastIndex = closes.length - 1;
        while (lastIndex >= 0 && closes[lastIndex] === null) {
          lastIndex--;
        }

        if (firstIndex < lastIndex) {
          const firstPrice = closes[firstIndex];
          const lastPrice = closes[lastIndex];
          const percentageChange = ((lastPrice - firstPrice) / firstPrice) * 100;
		  const AbsolutepercentageChange = Math.abs(((lastPrice - firstPrice) / firstPrice) * 100);
          setVIXMonthlyChange(AbsolutepercentageChange.toFixed(1));
        } else {
          setVIXMonthlyChange(null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setVIXMonthlyChange(null);
      }
    };
	fetchDataVIX();
    fetchData();
  }, []);
  return (
    <div style={{width: "100%", height: "100vh",}}>
	{/*

	<div style={{display: "flex", flexWrap: "wrap", width: "100%", height: "100%", position: "relative", top: "10.74%", left: "1.7578125vh", overflow: "hidden"}}>
		<div style={{marginRight: '2.74%', marginBottom: '-10vh', width: '57.36%', height: '52.83vh', background: '#E6E8FD', borderRadius: 57}} />
		<div style={{marginBottom: '-10vh', width: "35.68%", height: '52.83%', background: '#F0FDE6', borderRadius: 54}} />
		<div style={{marginRight: '2.74%', width: '51.97%', height: '31.93%', background: '#FDE6F6', borderRadius: 57}} ><p style={{fontSize: 20,marginTop: '20px', marginLeft: '40px', textAlign: 'left'}}>Your Portfolio</p></div>
		<div style={{width: '41.26%', height: '31.93%', background: '#FDF4E6', borderRadius: 54}} />
	</div>
	*/}
	{isLoggedIn ? (
	<div>
		<div className="text-wrapper-39">Hello {user.username} 👋🏼,</div>
				<div className="overview">
					<div className="overlap-9">
						<div className="group-10">
							<div className="overlap-group-5">
								<div className="group-11">
									<div className="text-wrapper-40">S&amp;P 500</div>
									<div className="text-wrapper-41">{marketPrice !== null ? (<div>${marketPrice}</div>) : (<div></div>)}</div>				  
								<div className="group-12" />
								<img className="combo-chart" alt="Combo chart" src="https://c.animaapp.com/GEOYKsql/img/combo-chart@2x.png"/>
								<div className="group-13">
									<p style={{margin:'0px'}} className="p">
										<span className="span">{monthlyChange}%</span>
										<span className="text-wrapper-42"> in the last month</span>
									</p>
									<img className="arrow-up" alt="Arrow up" src="https://c.animaapp.com/GEOYKsql/img/arrow-up-1-2.svg"/>
								</div>
								<div className="group-14">
									<p style={{margin:'0px'}} className="p">
										<span className="span">3</span>
										<span className="text-wrapper-42"> in the last month</span>
									</p>
									<img
										className="arrow-up"
										alt="Arrow up"
										src="https://c.animaapp.com/GEOYKsql/img/arrow-up-1-1.svg"
									/>
								</div>
							</div>
							<div className="group-15">
								<div className="text-wrapper-40">VIX</div>
									<div className="text-wrapper-41">{marketPrice !== null ? (<div>${VIXmarketPrice}</div>) : (<div></div>)}</div>
									<div className="group-12" />
									<div className="group-16">
										<p style={{margin:'0px'}} className="p">
											<span className="text-wrapper-43">{VIXmonthlyChange}%</span>
											<span className="text-wrapper-42"> this month</span>
										</p>
										<img className="arrow-up" alt="Arrow up" src="https://c.animaapp.com/GEOYKsql/img/arrow-up-1.svg" />
									</div>
									<img className="decrease" alt="Decrease" src="https://c.animaapp.com/GEOYKsql/img/decrease@2x.png" />
								</div>
								<div className="group-17">
									<div className="text-wrapper-40">Fear &amp; Greed Index</div>
									<div className="text-wrapper-41">78</div>
									<div className="group-12" />
									<img className="speed" alt="Speed" src="https://c.animaapp.com/GEOYKsql/img/speed@2x.png" />
								</div>
								<img className="line-9" alt="Line" src="https://c.animaapp.com/GEOYKsql/img/line-2.svg" />
								<img className="line-10" alt="Line" src="https://c.animaapp.com/GEOYKsql/img/line-2.svg" />
							</div>
							
						</div>
					</div>
				</div>
				
				
				
				
				<div className="bigbox">
					<div className="overlap-9" style={{top:0}}>
						<div style = {{height: '100%'}}>
							<h3 style={{margin:0,paddingLeft: 38, paddingTop: 30, textAlign: 'left'}}>Stocks</h3>
						</div>
					</div>
				</div>
				
				
				
				
				
				
				
				
				
				
				
			</div>
				
	) : (<div style={{width:'100vw', height: '100vh', position: 'static', left: '0px'}}><SignInPage/></div>)}
    </div>
  );
};
export default Dashboard;