// src/TickerForm.js

import React, { useState } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import Switch from 'react-switch';
import dayjs from 'dayjs';

const TickerForm = ({ onFetchData }) => {
  const [ticker, setTicker] = useState('');
  const [interval, setInterval] = useState('1d');
  const [range, setRange] = useState('1mo');
  const [chartData, setChartData] = useState(null);
  const [sma50Enabled, setSma50Enabled] = useState(false);
  const [sma100Enabled, setSma100Enabled] = useState(false);
  const [sma200Enabled, setSma200Enabled] = useState(false);

  const handleFetchData = async () => {
    try {
      const proxyUrl = 'https://corsproxy.io/?';
      const apiUrl = `https://query1.finance.yahoo.com/v8/finance/chart/${ticker}?metrics=high?&interval=${interval}&range=${range}`;
      
      const response = await axios.get(proxyUrl + apiUrl);
      const data = response.data.chart.result[0];

      const sma50 = sma(data.indicators.quote[0].close, 50);
      const sma100 = sma(data.indicators.quote[0].close, 100);
      const sma200 = sma(data.indicators.quote[0].close, 200);

      setChartData({
        series: [
        {
          name: 'candle',
          type: 'candlestick',
          data: data.timestamp.map((timestamp, index) => ({
            x: new Date(timestamp * 1000),
            y: [
              data.indicators.quote[0].open[index],
              data.indicators.quote[0].high[index],
              data.indicators.quote[0].low[index],
              data.indicators.quote[0].close[index],
            ],
          })),
        },
        sma50Enabled && {
            name: 'SMA(50)',
            type: 'line',
            data: sma50.map((value, index) => ({
              x: new Date(data.timestamp[index] * 1000),
              y: value,
            })),
          },
          sma100Enabled && {
            name: 'SMA(100)',
            type: 'line',
            data: sma100.map((value, index) => ({
              x: new Date(data.timestamp[index] * 1000),
              y: value,
            })),
          },
          sma200Enabled && {
            name: 'SMA(200)',
            type: 'line',
            data: sma200.map((value, index) => ({
              x: new Date(data.timestamp[index] * 1000),
              y: value,
            })),
          },
        ].filter(Boolean),
        options: {
		  stroke: {
			width: [1, 3, 3,3], // Adjust the width as needed
			colors: ['#eb4034', '#214aff', '#2cd42c'], // Specify your desired colors
		  },
		  legend: {
			 show: false,
		  },
          tooltip: {
			  enabled: false,
			  marker: {
				  show: false,
			  },
			  onDatasetHover: {
				  highlightDataSeries: false,
			  },
			  followCursor: false,
			intersect: false,
            custom: function ({ seriesIndex, dataPointIndex, w }) {
              const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex]
              const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
              const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
              const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex]
              return (''
              )
            }
          },
          chart: {
            type: 'candlestick',
            height: 350,
            toolbar: {
              autoSelected: 'none',
              show: false,
            },
          },
          states: {
            normal: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
            hover: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
          },
          grid: {
            position: 'back',
            borderColor: '#FDFBFF',
          },
          xaxis: {
            type: 'category',
            tickAmount: 4,
            labels: {
			formatter: function (val) {
			  // Check if chartData and its options are available
			  if (chartData && chartData.options && chartData.options.chart) {
				const range = chartData.options.chart.range;

				// Customize date-time formats based on the selected range
				if (range === '1d') {
				  // Format for 1 day
				  return dayjs(val).format('HH:mm');
				} else if (['5d', '1mo', '3mo', '6mo'].includes(range)) {
				  // Format for 5 days, 1 month, 3 months, 6 months
				  return dayjs(val).format('DD HH');
				} else if (['1y', '2y', '5y', 'ytd', 'max'].includes(range)) {
				  // Format for 1 year, 2 years, 5 years, year-to-date, max
				  return dayjs(val).format('MMM YYYY');
				}
			  }

			  // Default format for other cases
			  return dayjs(val).format('MMM');
			}},
            crosshairs: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            opposite: true,
            tickAmount: 6,
            labels: {
			  formatter: function (val, index) {
				return val !== null ? val.toFixed(2) : val;
			  }
			},
            forceNiceScale: true,
            tooltip: {
              enabled: false,
            },
            crosshairs: {
              show: false,
            },
          },
          markers: {
            size: 0,
          },
          
        },
      });

      onFetchData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const sma = (data, period) => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      if (i >= period - 1) {
        const sum = data.slice(i - period + 1, i + 1).reduce((acc, val) => acc + val, 0);
        result.push(sum / period);
      } else {
        result.push(null);
      }
    }
    return result;
  };

  return (
    <div>
      <label>
        Ticker:
        <input type="text" value={ticker} onChange={(e) => setTicker(e.target.value)} />
      </label>
      <label>
        Interval:
        <select value={interval} onChange={(e) => setInterval(e.target.value)}>
          <option value="1m">1m</option>
          <option value="5m">5m</option>
          <option value="15m">15m</option>
          <option value="1h">1h</option>
          <option value="1d">1d</option>
          <option value="1wk">1wk</option>
          <option value="1mo">1mo</option>
          {/* Add more options as needed */}
        </select>
      </label>
      <label>
        Range:
        <select value={range} onChange={(e) => setRange(e.target.value)}>
          <option value="1d">1d</option>
          <option value="5d">5d</option>
          <option value="1mo">1mo</option>
          <option value="3mo">3mo</option>
          <option value="6mo">6mo</option>
          <option value="1y">1yr</option>
          <option value="2y">2yr</option>
          <option value="5y">5yr</option>
          <option value="ytd">Ytd</option>
          <option value="max">Max</option>
          {/* Add more options as needed */}
        </select>
      </label>
      <div>
        <label>
          SMA(50):
          <Switch
            onChange={(checked) => setSma50Enabled(checked)}
            checked={sma50Enabled}
          />
        </label>
        <label>
          SMA(100):
          <Switch
            onChange={(checked) => setSma100Enabled(checked)}
            checked={sma100Enabled}
          />
        </label>
        <label>
          SMA(200):
          <Switch
            onChange={(checked) => setSma200Enabled(checked)}
            checked={sma200Enabled}
          />
        </label>
      </div>
      <button onClick={handleFetchData}>Fetch Data</button>

      {chartData && (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="candlestick"
          height={350}
        />
      )}
    </div>
  );
};

export default TickerForm;
