import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../context/UserContext';

const OrderHistory = () => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [sortColumn, setSortColumn] = useState('datetime');
  const [sortOrder, setSortOrder] = useState('asc');
  const { user, isLoggedIn } = useUser();


	




  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        if (isLoggedIn && user) {
          const response = await axios.get(`https://app.stocksleuth.xyz:5000/auth/portfolio?username=${user.username}`);
          const portfolioData = response.data.portfolio;

          // Extract order history from portfolio data
          const extractedOrderHistory = extractOrderHistory(portfolioData);

          setOrderHistory(extractedOrderHistory || []);
        }
      } catch (error) {
        console.error('Failed to fetch order history', error);
      }
    };

    fetchOrderHistory();
  }, [user, isLoggedIn]);
	
  // Function to extract order history from portfolio data
  const extractOrderHistory = (portfolioData) => {
  const orderHistory = [];

  Object.keys(portfolioData).forEach((stock) => {
    if (portfolioData[stock].length > 0) {
      portfolioData[stock].forEach((purchase) => {
        const shares = Number(purchase.shares);
        const purchasePrice = Number(purchase.purchasePrice);
        const tradeValue = -1 * shares * purchasePrice;

        // Include positions only if they have non-zero shares
        if (shares !== 0) {
          // Parse and format the datetime string
          const dateObj = new Date(purchase.datetime);
          const formattedDatetime =
            dateObj.getFullYear() +
            '-' +
            ('0' + (dateObj.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + dateObj.getDate()).slice(-2) +
            '@' +
            ('0' + dateObj.getHours()).slice(-2) +
            '.' +
            ('0' + dateObj.getMinutes()).slice(-2);
			console.log(stock, shares);
          orderHistory.push({
            ticker: stock,
            shares: shares,
            purchasePrice: purchasePrice,
            datetime: formattedDatetime,
            tradeValue: tradeValue.toFixed(2), // Include trade value
          });
        }
      });
    }
  });

  return orderHistory;
};


  // Calculate total profit by summing up trade values
  const totalProfit = orderHistory.reduce((sum, order) => sum + Number(order.tradeValue), 0).toFixed(2);

  // Function to handle sorting
  const handleSort = (column) => {
    // Toggle the sorting order if the same column is clicked again
    setSortOrder((prevSortOrder) => (column === sortColumn ? (prevSortOrder === 'asc' ? 'desc' : 'asc') : 'asc'));
    setSortColumn(column);
  };

  // Function to sort order history based on the current sorting column and order
  const sortedOrderHistory = orderHistory.sort((a, b) => {
  const aValue = a[sortColumn];
  const bValue = b[sortColumn];

  if (sortOrder === 'asc') {
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      return aValue - bValue;
    } else {
      return 0; // Default case, do not change the order
    }
  } else {
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return bValue.localeCompare(aValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      return bValue - aValue;
    } else {
      return 0; // Default case, do not change the order
    }
  }
}).reverse();
const handleRemovePosition = async (ticker, datetime) => {
    try {
      // Send a request to the server to remove the position
      await axios.delete(`https://app.stocksleuth.xyz:5000/auth/portfolio/remove-position`, {
        params: { username: user.username, ticker, datetime },
      });

      // Fetch updated order history
      //fetchOrderHistory();
    } catch (error) {
      console.error('Failed to remove position', error);
    }
  };
  return (
    <div>
      {isLoggedIn ? (
        <div>
          {user && user.username ? (
            <div style={{display: 'flex',justifyContent: 'center'}}>
              {orderHistory.length === 0 ? (
                <p>Your order history is empty.</p>
              ) : (
                <table style={{marginTop: 30,width: "90%"}}>
                  <thead>
                    <tr>
                      <th onClick={() => handleSort('ticker')}>Ticker</th>
                      <th onClick={() => handleSort('shares')}>#</th>
                      <th onClick={() => handleSort('purchasePrice')}>Price</th>
                      <th onClick={() => handleSort('tradeValue')}>Value</th>
                      <th onClick={() => handleSort('datetime')} style={{width: '50%'}}>Date</th>
					  <th >Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedOrderHistory.map((order, index) => (
                      <tr key={index}>
                        <td>{order.ticker}</td>
                        <td>{order.shares}</td>
                        <td>{order.purchasePrice}</td>
                        <td>{order.tradeValue}</td>
                        <td>{order.datetime}</td>
						<td>
						<button className="bin-button" onClick={() => handleRemovePosition(order.ticker, order.datetime)}>
						  🗑️
						</button>
					  </td>
                      </tr>
                    ))}
                    {/* Summary row for total profit */}
                    <tr>
						
						
                      <td colSpan="3">Total</td>
                      <td>{totalProfit}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            <p>No username found. Please sign in.</p>
          )}
        </div>
      ) : (
        <div>
          <p>User not logged in</p>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
