import React, { useState, useContext } from 'react';
import UserContext from '../context/UserContext';
import axios from 'axios';
import '../Login.css';
import seePass from '../images/seePassword.png';

const AccountPage = () => {
  const { user, setUser } = useContext(UserContext);
  const [newUsername, setNewUsername] = useState(user?.username || ''); // Add null check here
  const [newEmail, setNewEmail] = useState(user?.email || ''); // Add null check here
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const [image, setImage] = useState(null);
const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	};
	const handleTogglePasswordC = () => {
		setShowPasswordC(!showPasswordC);
	};
  const handleUpdateAccount = async () => {
	  // Validate username
  if (newUsername.trim() === '') {
    setUsernameError('Username cannot be empty');
    return;
  } else {
    setUsernameError('');
  }

  // Validate email (if it's not empty)
  if (newEmail.trim() !== '') {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newEmail)) {
      setEmailError('Invalid email address');
      return;
    } else {
      setEmailError('');
    }
  }

  // Validate current password
  if (currentPassword.trim() === '') {
    setPasswordError('Current password cannot be empty');
    return;
  } else {
    setPasswordError('');
  }

  // Validate new password (if it's not empty)
  if (newPassword.trim() !== '' && newPassword.length < 8) {
    setPasswordError('New password must be at least 8 characters long');
    return;
  } else {
    setPasswordError('');
  }
	  
	  
	  
    

    try {
		const formData = new FormData();
		formData.append('userId', user.id);
		formData.append('newUsername', newUsername);
		formData.append('newEmail', newEmail);
		formData.append('currentPassword', currentPassword);
		formData.append('newPassword', newPassword);
		formData.append('image', image);
		console.log('Image Blob:', image);
      // Make an API request to the update account endpoint in your Node.js backend
      const response = await axios.post('https://app.stocksleuth.xyz:5000/auth/update', formData);

      // Update the user in the context with the updated information
      setUser(response.data.updatedUser);
      console.log('Account updated successfully:', response.data);
    } catch (error) {
      console.error('Account update failed', error);
    }
  };
	
	
	const handleImageChange = (e) => {
  const file = e.target.files[0];

  if (file) {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set the canvas dimensions to your desired size, e.g., 128x128
        canvas.width = 128;
        canvas.height = 128;

        // Draw the image on the canvas with the desired size
        ctx.drawImage(img, 0, 0, 128, 128);

        // Convert the canvas content to a Blob object (compressed image file)
        canvas.toBlob((blob) => {
          setImage(blob);
        }, 'image/jpeg', 0.7);
      };
    };

    reader.readAsDataURL(file);
  }
};
  return (
  <div style={{height: '100vh', width: '100vw ', display: 'flex', alignItems: "center",
			justifyContent: "center",
			textAlign: "center", position: 'absolute', left: '0px'}}>
  <div className="AccountForm">
			<h2 className = "LoginHeader" style = {{margin: "0px", position: 'relative', left: 0, top: 0, textAlign: 'left'}}>Account Settings</h2>
			
			<div className = "EmailInputUp">
			<input className="EmailPlaceholder" style={{color: '#A7A3FF',padding: '0px',position: 'relative',top: '20px', left: '26px',width: '188px',height: '23px', border: 'none', background: 'none' }}type="text" placeholder="Email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
			</div>
			
			<div className = "UsernameInputUp">
			<input className='UserPlaceholder' style={{color: '#A7A3FF',padding: '0px',position: 'relative',top: '20px', left: '26px',width: '188px',height: '23px', border: 'none', background: 'none' }} type='text' placeholder="Username" value={newUsername} onChange={(e) => setNewUsername(e.target.value)} />
			
			</div>
			
			<div className = "PasswordInputUp">
			<input className='passPlaceholder' style={{color: '#A7A3FF',padding: '0px',position: 'relative',top: '20px', left: '26px',width: '188px',height: '23px', border: 'none', background: 'none' }} type={showPassword ? 'text' : 'password'} placeholder="Password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
			<img onClick={handleTogglePassword} style={{position: 'absolute',top: '23px', right: '32px'}} src={seePass} />
			</div>
			
			<div className = "ConfirmPasswordInputUp">
			<input className='passPlaceholder' style={{color: '#A7A3FF',padding: '0px',position: 'relative',top: '20px', left: '26px',width: '188px',height: '23px', border: 'none', background: 'none' }} type={showPasswordC ? 'text' : 'password'} placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
			<img onClick={handleTogglePasswordC} style={{position: 'absolute',top: '23px', right: '32px'}} src={seePass} />
			</div>
			<input type="file" accept="image/*" onChange={handleImageChange} />
			{passwordError && <p style={{ color: 'black' }}>{passwordError}</p>}
			<div className="SignUpButton"onClick={handleUpdateAccount}><p className="LogIn"style={{margin: '0px'}}>update Account</p></div>
			
		
		{/*<div>
      <h2>Account Settings</h2>
      <label>
        New Username
        <input type="text" value={newUsername} onChange={(e) => setNewUsername(e.target.value)} />
      </label>
      {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}

      <label>
        New Email
        <input
        type="text"
        placeholder={user?.email || 'Email'} // Use user.email as a placeholder
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
      />
      </label>
      {emailError && <p style={{ color: 'red' }}>{emailError}</p>}

      <label>
        Current Password
        <input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
      </label>

      <label>
        New Password (optional)
        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
      </label>
      {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}

      <button onClick={handleUpdateAccount}>Update Account</button>
		</div>*/}</div></div>
  );
};

export default AccountPage;
