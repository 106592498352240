// UserContext.js
import React, { createContext, useState, useContext } from 'react';
import cookies from 'js-cookie';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  console.log(user);
  const logout = () => {
    // Clear the authentication token from cookies
    cookies.remove('authToken');

    setUser(null);
    setIsLoggedIn(false);
  };
  const value = {
    user,
    setUser,
    isLoggedIn: user !== null, 
	logout,
  };
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export default UserContext; // Add this line to export UserContext