import React, { useState, useEffect } from 'react';	
import { useUser } from '../context/UserContext';
import SignUp from '../components/SignUp';
import SignOut from '../components/SignOut';
//
const SignUpPage = () => {
  const { user, isLoggedIn } = useUser();

  return (
    <div>
      {isLoggedIn ? (
        <div>
          
        </div>
      ) : (
        <div>
          <SignUp />
        </div>
      )}
    </div>
  );
};
export default SignUpPage;

